import { Card, Carousel, CarouselItem, Image } from "react-bootstrap";

export default function Donate() {
  return (
    <Card>
      <Card.Title className="text-center mb-0">DONATE</Card.Title>
      <Card.Body className="p-2">
        <Carousel controls={false} indicators={false}>
          <CarouselItem className="text-center">
            <Image src="gcash-logo.png" className="img-fluid mb-1" />
            <Image src="gcash.png" className="img-fluid" />
          </CarouselItem>
          <CarouselItem className="text-center">
            <Image src="bpi-logo.png" className="img-fluid mb-1" />
            <Image src="bpi.png" className="img-fluid" />
          </CarouselItem>
          <CarouselItem className="text-center">
            <Image src="maya-logo.png" className="img-fluid mb-1" />
            <Image src="maya.png" className="img-fluid" />
          </CarouselItem>
        </Carousel>
      </Card.Body>
    </Card>
  );
}
