import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { authService } from "../../services/authService";
import { karaokeService } from "../../services/karaokeService";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { CONNECTION_STATE, KARAOKE_EVENTS } from "../../constants";
import Controller from "./Controller";
import Search from "./Search";
import { Badge, Button, Form, Image, Tab, Tabs } from "react-bootstrap";
import "./Guest.css";
import Favorites from "./Favorites";
import Reserved from "./Reserved";
import { GuestContext } from "./GuestContext";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../constants";
import logo from "../../brand/karaokey.png";
import Notes from "../_shared/Notes";

function Guest() {
  const navigate = useNavigate();
  const params = useParams();
  const { roomId } = params;
  const n = karaokeService.getName();
  const [name, setName] = useState(n);
  const t = karaokeService.getToken();
  const [token, setToken] = useState(t);
  const [isRobot, setIsRobot] = useState(!n || !t);
  const [connection, setConnection] = useState();
  const [queue, setQueue] = useState([]);

  const [favorites, setFavorites] = useState([]);
  const guestContext = { favorites, setFavorites };

  const onSelect = (eventKey) => {
    if (eventKey === "search") {
      const txtSearch = document.getElementById("txtSearch");
      txtSearch.focus();
    }
  };

  const onReCaptchaChange = async (captcha) => {
    var response = await authService.validateReCaptchaToken(captcha);

    if (!response.isSuccessful || !response.isValidToken) {
      return;
    }

    setToken(response.token);
  };

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const joinRoom = () => {
    if (!name?.length || !token?.length) {
      return;
    }

    karaokeService.joinRoom(name, token);
    setIsRobot(false);
  };

  useEffect(() => {
    if (!karaokeService.validateRoomId(roomId)) {
      navigate("/");
      return;
    }

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_BASEURL}/hubs/karaoke`)
      .withAutomaticReconnect([0, 3000])
      .build();

    const joinRoom = async () => {
      if (newConnection.state !== CONNECTION_STATE.CONNECTED) {
        return null;
      }

      await newConnection.invoke(KARAOKE_EVENTS.JOIN_ROOM, roomId);

      setConnection(newConnection);
    };

    const startConnection = async () => {
      try {
        await newConnection.start();
        await joinRoom();
      } catch {
        setConnection(null);
        setTimeout(startConnection, 1000);
      }
    };

    newConnection.onreconnecting(() => {
      setConnection(null);
    });

    newConnection.onreconnected(async () => {
      await joinRoom();
    });

    newConnection.onclose(async () => {
      setConnection(newConnection);
      await startConnection();
    });

    newConnection.on(KARAOKE_EVENTS.QUEUE_UPDATED, (songs) => {
      setQueue(songs);
    });

    startConnection();
  }, [navigate, roomId]);

  return (
    <>
      <Notes />
      {isRobot ? (
        <div className="m-4 text-center">
          <Image src={logo} className="logo mb-1" />
          <Form.Control
            placeholder="Your Name"
            id="txtSearch"
            onChange={onNameChange}
            className="mb-1"
            size="lg"
          />
          <ReCAPTCHA
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={onReCaptchaChange}
            className="mb-1"
          />
          <Button variant="primary" size="xl" onClick={joinRoom}>
            LET'S PARTY!
          </Button>
        </div>
      ) : (
        <GuestContext.Provider value={guestContext}>
          {connection?.state !== CONNECTION_STATE.CONNECTED ? (
            <span className="bg-primary text-dark connection-status">
              Connecting...
            </span>
          ) : (
            <></>
          )}
          <div className="tabs-container">
            <div className="d-flex flex-column justify-content-center align-items-center m-1 h-100">
              <Tabs
                className="fixed-bottom bg-light"
                justify
                onSelect={onSelect}
              >
                <Tab
                  eventKey="search"
                  title={
                    <div className="d-flex flex-column">
                      <i className="fa fa-search" />
                      <small>Search</small>
                    </div>
                  }
                >
                  <Tab.Content>
                    <Search
                      connection={connection}
                      roomId={roomId}
                      name={name}
                    />
                  </Tab.Content>
                </Tab>
                <Tab
                  eventKey="favorites"
                  title={
                    <div className="d-flex flex-column">
                      <i className="fa fa-heart" />
                      <small>Favorites</small>
                    </div>
                  }
                >
                  <Tab.Content>
                    <Favorites
                      connection={connection}
                      roomId={roomId}
                      name={name}
                    />
                  </Tab.Content>
                </Tab>
                <Tab
                  eventKey="npw-playing"
                  title={
                    <div className="d-flex flex-column">
                      <i className="fa fa-play" />
                      <small>Playing</small>
                    </div>
                  }
                >
                  <Tab.Content>
                    <Controller
                      connection={connection}
                      roomId={roomId}
                      currentSong={queue[0]}
                    />
                  </Tab.Content>
                </Tab>
                <Tab
                  eventKey="reserved"
                  title={
                    <div className="d-flex flex-column">
                      <div>
                        <i className="fa fa-list" />
                        {queue.length > 1 ? (
                          <Badge bg="danger" style={{ position: "absolute" }}>
                            {queue.length - 1}
                          </Badge>
                        ) : (
                          <></>
                        )}
                      </div>
                      <small>Reserved</small>
                    </div>
                  }
                >
                  <Tab.Content>
                    <Reserved
                      connection={connection}
                      roomId={roomId}
                      queue={queue}
                    />
                  </Tab.Content>
                </Tab>
              </Tabs>
            </div>
          </div>
        </GuestContext.Provider>
      )}
    </>
  );
}

export default Guest;
