import { Modal, Button, Card } from "react-bootstrap";
import YouTube from "react-youtube";
import { CONNECTION_STATE, KARAOKE_EVENTS } from "../../constants";

export default function PreviewSongDialog({
  connection,
  roomId,
  name,
  show,
  handleClose,
  song,
}) {
  const handleOnHide = () => {
    handleClose();
  };

  const reserveSong = async () => {
    if (!connection || connection.state !== CONNECTION_STATE.CONNECTED) {
      return null;
    }

    await connection.invoke(KARAOKE_EVENTS.RESERVE_SONG, roomId, song, name);

    handleClose();
  };

  const reserveFirst = async () => {
    if (!connection || connection.state !== CONNECTION_STATE.CONNECTED) {
      return null;
    }

    await connection.invoke(KARAOKE_EVENTS.RESERVE_FIRST, roomId, song, name);

    handleClose();
  };

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      showinfo: 0,
      autoplay: 1,
      enablejsapi: 1,
      iv_load_policy: 3,
      disablekb: 1,
    },
  };

  return (
    <Modal
      show={show}
      onHide={handleOnHide}
      backdrop="static"
      centered
      keyboard={false}
    >
      <Modal.Title className="text-center">
        <span
          className="h6"
          dangerouslySetInnerHTML={{ __html: song?.title }}
        ></span>
      </Modal.Title>
      <Modal.Body className="mx-auto p-3 w-100">
        <Card bg="dark" className="h-100 w-100">
          <Card.Body style={{ padding: 0 }}>
            <YouTube videoId={song?.id} opts={opts} className="h-100 w-100" />
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <Button
          variant="warning"
          onClick={reserveFirst}
          className="align-self-left"
        >
          RESERVE 1st
        </Button>
        <div>
          <Button
            variant="success"
            onClick={reserveSong}
            style={{ marginRight: "5px" }}
          >
            RESERVE
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            CANCEL
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
