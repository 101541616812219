import { Card } from "react-bootstrap";
import YouTube from "react-youtube";
import {
  CONNECTION_STATE,
  KARAOKE_EVENTS,
  SELECT_A_SONG_ID,
} from "../../constants";
import React, { useCallback, useEffect, useRef } from "react";
import { songsService } from "../../services/songsService";

const playNextSong = (player) => {
  const next = songsService.getNextSong();
  let videoId = SELECT_A_SONG_ID;

  if (next) {
    videoId = next.id;
  }

  player.loadVideoById(videoId);
};

const onSongEnded = (player, connection, roomId) => {
  const songs = songsService.getSongs();
  if (songs.length) {
    songsService.dequeueFirstSong();
  }

  playNextSong(player);

  invokeSongEnded(connection, roomId);
};

const invokeSongEnded = (connection, roomId) => {
  if (!connection || connection.state !== CONNECTION_STATE.CONNECTED) {
    return null;
  }

  const next = songsService.getNextSong();

  connection.invoke(KARAOKE_EVENTS.SONG_ENDED, roomId, next);
};

export default function VideoPlayer({ roomId, connection }) {
  const playerRef = useRef();

  const onReady = (e) => {
    playNextSong(e.target);

    setInterval(() => {
      var videoData = e.target.getVideoData();
      var currentVideoId = videoData.video_id;
      if (currentVideoId !== SELECT_A_SONG_ID) {
        updateProgress(e.target);
      }
    }, 1000);
  };

  const updateProgress = async (player) => {
    let currentTime = await player.getCurrentTime();
    let duration = await player.getDuration();

    if (!connection || connection.state !== CONNECTION_STATE.CONNECTED) {
      return;
    }

    currentTime |= 0;
    duration |= 100;

    connection.invoke(
      KARAOKE_EVENTS.UPDATE_PROGRESS,
      roomId,
      currentTime,
      duration
    );
  };

  const playPause = async () => {
    const playerState = await playerRef.current.internalPlayer.getPlayerState();
    if (playerState === 2 || playerState === -1) {
      await playerRef.current.internalPlayer.playVideo();
    } else {
      await playerRef.current.internalPlayer.pauseVideo();
    }
  };

  const setPlayerProgress = async (seconds) => {
    await playerRef.current.internalPlayer.seekTo(seconds | 0, true);
    await playerRef.current.internalPlayer.playVideo();
  };

  const nextSong = useCallback(() => {
    onSongEnded(playerRef.current.internalPlayer, connection, roomId);
  }, [connection, roomId]);

  const setProgress = useCallback((seconds) => {
    setPlayerProgress(seconds);
  }, []);

  useEffect(() => {
    if (connection) {
      connection.on(KARAOKE_EVENTS.RESERVE_SONG, async (song, name) => {
        const songs = songsService.queueSong(song, name);

        await connection.invoke(KARAOKE_EVENTS.QUEUE_UPDATED, roomId, songs);

        if (songs.length === 1) {
          playerRef.current.internalPlayer.loadVideoById(songs[0].id);
        }
      });

      connection.on(KARAOKE_EVENTS.RESERVE_FIRST, async (song, name) => {
        const songs = songsService.queuePrioritySong(song, name);

        await connection.invoke(KARAOKE_EVENTS.QUEUE_UPDATED, roomId, songs);

        if (songs.length === 1) {
          playerRef.current.internalPlayer.loadVideoById(songs[0].id);
        }
      });

      connection.on(KARAOKE_EVENTS.REMOVE_SONG, async (id) => {
        const songs = songsService.removeSong(id);

        await connection.invoke(KARAOKE_EVENTS.QUEUE_UPDATED, roomId, songs);
      });

      connection.on(KARAOKE_EVENTS.PLAY_PAUSE, async () => {
        await playPause();
      });

      connection.on(KARAOKE_EVENTS.RELOAD_SONG, async () => {
        await setProgress(0);
      });

      connection.on(KARAOKE_EVENTS.NEXT_SONG, () => {
        nextSong();
      });

      connection.on(KARAOKE_EVENTS.SET_PROGRESS, (seconds) => {
        setProgress(seconds);
      });
    }
  }, [connection, roomId, nextSong, setProgress]);

  const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
      showinfo: 0,
      autoplay: 1,
      enablejsapi: 1,
      iv_load_policy: 3,
      disablekb: 1,
    },
  };

  return (
    <Card bg="dark" className="h-100">
      <Card.Body style={{ padding: 0 }}>
        <YouTube
          videoId={SELECT_A_SONG_ID}
          opts={opts}
          className="h-100"
          onEnd={(e) => {
            onSongEnded(e.target, connection, roomId);
          }}
          onReady={onReady}
          ref={playerRef}
        />
      </Card.Body>
    </Card>
  );
}
