import { useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../constants";

export default function ReCaptchaDialog({
  show,
  handleClose,
  onSuccess,
  loadingText,
}) {
  const [creatingRoom, setCreatingRoom] = useState(false);

  const handleOnHide = () => {
    setCreatingRoom(false);
    handleClose();
  };

  const onChange = async (token) => {
    setTimeout(async () => {
      setCreatingRoom(true);
      await onSuccess(token);
    }, 1000);
  };

  return (
    <Modal
      show={show}
      onHide={handleOnHide}
      backdrop="static"
      centered
      keyboard={false}
    >
      {!creatingRoom ? (
        <>
          <Modal.Body className="mx-auto">
            <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY} onChange={onChange} />
          </Modal.Body>
          <Modal.Footer>
            <Button size="lg" variant="secondary" onClick={handleClose}>
              CANCEL
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <Modal.Body className="mx-auto">
          <Spinner as="span" animation="grow" size="sm" variant="info" />
          {loadingText ? loadingText : "Please wait..."}
        </Modal.Body>
      )}
    </Modal>
  );
}
