import { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import VideoPlayer from "./VideoPlayer";
import QrCode from "./QrCode";
import NextSong from "./NextSong";
import { CONNECTION_STATE, KARAOKE_EVENTS } from "../../constants";
import { karaokeService } from "../../services/karaokeService";
import { useNavigate } from "react-router-dom";
import { songsService } from "../../services/songsService";
import Donate from "./Donate";

export default function Karaoke() {
  const navigate = useNavigate();

  const roomId = karaokeService.getRoomId();

  const [connection, setConnection] = useState();

  useEffect(() => {
    if (!karaokeService.validateRoomId(roomId)) {
      navigate("/");
      return;
    }

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_BASEURL}/hubs/karaoke`)
      .withAutomaticReconnect([0, 3000])
      .build();

    const joinRoom = async () => {
      if (newConnection.state !== CONNECTION_STATE.CONNECTED) {
        return null;
      }

      await newConnection.invoke(KARAOKE_EVENTS.JOIN_ROOM, roomId);

      setConnection(newConnection);
    };

    const startConnection = async () => {
      try {
        await newConnection.start();

        await joinRoom();

        setInterval(async () => {
          if (newConnection.state !== CONNECTION_STATE.CONNECTED) {
            return null;
          }

          const songs = songsService.getSongs();
          await newConnection.invoke(
            KARAOKE_EVENTS.QUEUE_UPDATED,
            roomId,
            songs
          );
        }, 1000);
      } catch {
        setTimeout(startConnection, 1000);
      }
    };

    newConnection.onreconnecting(() => {
      setConnection(newConnection);
    });

    newConnection.onreconnected(async () => {
      await joinRoom();
    });

    newConnection.onclose(async () => {
      setConnection(newConnection);
      await startConnection();
    });

    startConnection();
  }, [navigate, roomId]);

  return (
    <div className="container-fluid vh-100 d-flex p-0 flex-column">
      <div className="row flex-grow-1 p-0 m-1">
        <div className="col p-0 m-1">
          {connection ? (
            <VideoPlayer roomId={roomId} connection={connection} />
          ) : (
            <></>
          )}
        </div>
        <div className="d-flex flex-column col-md-2 col-xxl-1 p-0 m-1">
          <div className="row">
            <div className="col col-md-12 pb-2">
              <QrCode roomId={roomId} />
            </div>
            <div className="col col-md-12 pb-2">
              {connection ? <NextSong connection={connection} /> : <></>}
            </div>
          </div>
          <div className="mt-auto">
            {connection ? <Donate connection={connection} /> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
}
