import { Form, InputGroup } from "react-bootstrap";
import { songsService } from "../../services/songsService";
import { useContext, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { GuestContext } from "./GuestContext";
import PreviewSongDialog from "./PreviewSongDialog";
import Song from "./Song";

function Search({ connection, roomId, name }) {
  const [isBusy, setIsBusy] = useState(true);
  const [showPreviewDialog, setShowPreviewDialog] = useState();
  const [songPreview, setSongPreview] = useState();
  const [result, setResult] = useState([]);
  const { favorites, setFavorites } = useContext(GuestContext);

  const searchSong = async (keyword) => {
    if (keyword?.length < 3) {
      return;
    }

    setIsBusy(true);

    const response = await songsService.searchSong(keyword);
    // const response = {
    //   isSuccessful: true,
    //   result: [
    //     {
    //       id: "d0p2Zx_VI3E",
    //       title: "PANAGINIP - iluna (KARAOKE)",
    //       thumbnail: "https://i.ytimg.com/vi/d0p2Zx_VI3E/mqdefault.jpg",
    //     },
    //     {
    //       id: "grTVi87sGCI",
    //       title: "The Shade - Rex Orange County (Karaoke Version)",
    //       thumbnail: "https://i.ytimg.com/vi/grTVi87sGCI/mqdefault.jpg",
    //     },
    //     {
    //       id: "wvdEiPvDezY",
    //       title: "Rex Orange County - Amazing (Videoke)",
    //       thumbnail: "https://i.ytimg.com/vi/wvdEiPvDezY/mqdefault.jpg",
    //     },
    //     {
    //       id: "ckZuabxMjlw",
    //       title: "Sunflower - Rex Orange County (Karaoke Version)",
    //       thumbnail: "https://i.ytimg.com/vi/ckZuabxMjlw/mqdefault.jpg",
    //     },
    //   ],
    // };

    setIsBusy(false);

    if (!response.isSuccessful) {
      return;
    }

    setResult(response.result);
  };

  const addRemoveToFavorites = (song) => {
    const newFavorites = songsService.addRemoveToFavorites(song);

    setFavorites(newFavorites);
  };

  const onChange = debounce(async (e) => {
    await searchSong(e.target.value);
  }, 1000);

  const showPreview = (s) => {
    setSongPreview(s);
    setShowPreviewDialog(true);
  };

  const handleClosePreview = () => {
    setShowPreviewDialog(false);
  };

  useEffect(() => {
    const search = async () => {
      await searchSong("karaoke");
    };

    search();

    const f = songsService.getFavorites();
    setFavorites(f);
  }, [setFavorites]);

  return (
    <>
      <InputGroup size="lg" className="mb-2">
        <InputGroup.Text>
          <i className="fa fa-search" />
        </InputGroup.Text>
        <Form.Control
          placeholder="Search Songs"
          id="txtSearch"
          onChange={onChange}
          type="search"
        />
      </InputGroup>
      <div className="list-container" style={{ paddingBottom: "121px" }}>
        <div className="list-group">
          {!isBusy
            ? result.map((song) => {
                return (
                  <Song
                    key={song.id}
                    song={song}
                    onSelected={showPreview}
                    actions={
                      <button
                        type="button"
                        className="btn btn-light btn-lg m-1 p-0 text-danger btn-favorite"
                        onClick={() => {
                          addRemoveToFavorites(song);
                        }}
                      >
                        <i
                          className={
                            favorites.find((x) => x.id === song.id)
                              ? "fa fa-heart"
                              : "fa fa-heart-o"
                          }
                          style={{
                            fontSize: "30px",
                          }}
                        ></i>
                      </button>
                    }
                  />
                );
              })
            : [1, 2, 3].map((i) => {
                return (
                  <div className="list-group-item p-1 mb-1" key={i}>
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center flex-grow-1">
                        <div
                          className="skeleton"
                          style={{ width: "150px", height: "84px" }}
                        ></div>
                        <div className="w-100">
                          <div className="m-2 skeleton w-75"></div>
                          <div className="m-2 skeleton"></div>
                          <div className="m-2 skeleton w-25"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
      </div>
      <PreviewSongDialog
        connection={connection}
        roomId={roomId}
        name={name}
        show={showPreviewDialog}
        song={songPreview}
        handleClose={handleClosePreview}
      />
    </>
  );
}

export default Search;
