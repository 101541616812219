export const RECAPTCHA_SITE_KEY = "6LdNIMwoAAAAAIo5DUixAv8E7klkC8CRoPrIRPCx";

export const STORAGE_KEYS = {
  TOKEN: "token",
  ROOM_ID: "roomId",
  SONGS: "songs",
  FAVORITES: "favorites",
  NAME: "name",
};

export const SELECT_A_SONG_ID = "AEga3BYvym8";

export const KARAOKE_EVENTS = {
  JOIN_ROOM: "JoinRoom",
  RESERVE_SONG: "ReserveSong",
  RESERVE_FIRST: "ReserveFirst",
  REMOVE_SONG: "RemoveSong",
  PLAY_PAUSE: "PlayPause",
  RELOAD_SONG: "ReloadSong",
  NEXT_SONG: "NextSong",
  SONG_ENDED: "SongEnded",
  QUEUE_UPDATED: "QueueUpdated",
  UPDATE_PROGRESS: "UpdateProgress",
  SET_PROGRESS: "SetProgress",
};

export const CONNECTION_STATE = {
  CONNECTED: "Connected",
};
