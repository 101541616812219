import { useState } from "react";
import { Button, Row, Col, Image } from "react-bootstrap";
import ReCaptchaDialog from "./ReCaptchaDialog";
import { authService } from "../../services/authService";
import logo from "../../brand/karaokey.png";
import { karaokeService } from "../../services/karaokeService";
import Notes from "../_shared/Notes";

export default function Start({ next }) {
  const [showReCaptcha, setShowReCaptcha] = useState(false);

  const challengeRobot = () => {
    setShowReCaptcha(true);
  };

  const hideReCaptcha = () => {
    setShowReCaptcha(false);
  };

  const prepareRoom = async (token) => {
    var response = await authService.validateReCaptchaToken(token);

    if (!response.isSuccessful || !response.isValidToken) {
      return;
    }

    const roomId = karaokeService.createRoom();

    hideReCaptcha();

    if (next) {
      next(roomId);
    }
  };

  return (
    <>
      <Notes />
      <Row className="p-5 mb-5">
        <Col className="d-flex flex-column align-items-center justify-content-center">
          <Row className="mb-5">
            <Col>
              <Image src={logo} className="logo" />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex align-items-center justify-content-center">
              <Button variant="primary" size="xl" onClick={challengeRobot}>
                START THE PARTY!
              </Button>
            </Col>
          </Row>
        </Col>
        <ReCaptchaDialog
          show={showReCaptcha}
          handleClose={hideReCaptcha}
          onSuccess={prepareRoom}
          loadingText="Preparing you party place..."
        />
      </Row>
    </>
  );
}
