import jwtInterceptor from "./jwtInterceptor";
import { STORAGE_KEYS } from "../constants";
import { v4 as uuidv4 } from "uuid";
import { karaokeService } from "./karaokeService";

const searchSong = async (keyword) => {
  try {
    const response = await jwtInterceptor.get("/songs", {
      params: {
        keyword,
      },
    });

    return response.data;
  } catch (ex) {
    if (ex.response.status === 401) {
      karaokeService.removeToken();
      karaokeService.removeName();
      window.location.reload();
    }

    return { isSuccessful: false, result: [] };
  }
};

const getSongs = () => {
  const q = sessionStorage.getItem(STORAGE_KEYS.SONGS) || "[]";

  return JSON.parse(q);
};

const queueSong = (song, name) => {
  const queue = getSongs();
  const _id = uuidv4();
  queue.push({ singer: name, ...song, _id });

  sessionStorage.setItem(STORAGE_KEYS.SONGS, JSON.stringify(queue));

  return queue;
};

const queuePrioritySong = (song, name) => {
  const queue = getSongs();
  const _id = uuidv4();

  queue.splice(1, 0, { singer: name, ...song, _id });

  sessionStorage.setItem(STORAGE_KEYS.SONGS, JSON.stringify(queue));

  return queue;
};

const removeSong = (id) => {
  let songs = getSongs();

  songs = songs.filter((x) => x._id !== id);

  sessionStorage.setItem(STORAGE_KEYS.SONGS, JSON.stringify(songs));

  return songs;
};

const getNextSong = () => {
  const queue = getSongs();
  if (!queue.length) {
    return null;
  }

  return queue[0];
};

const dequeueFirstSong = () => {
  const queue = getSongs();

  queue.shift();

  sessionStorage.setItem(STORAGE_KEYS.SONGS, JSON.stringify(queue));
};

const getFavorites = () => {
  const favorites = localStorage.getItem(STORAGE_KEYS.FAVORITES) || "[]";

  return JSON.parse(favorites);
};

const addRemoveToFavorites = (song) => {
  let favorites = getFavorites();

  const s = favorites.find((x) => x.id === song.id);
  if (s) {
    favorites = favorites.filter((x) => x.id !== song.id);
  } else {
    favorites.push(song);
  }

  localStorage.setItem(STORAGE_KEYS.FAVORITES, JSON.stringify(favorites));

  return favorites;
};

export const songsService = {
  searchSong,
  getSongs,
  queueSong,
  removeSong,
  queuePrioritySong,
  getNextSong,
  dequeueFirstSong,
  getFavorites,
  addRemoveToFavorites,
};
