import axios from "axios";

const validateReCaptchaToken = async (token) => {
  const response = await axios.post("/auth/validate-recaptcha", { token });

  return response.data;
};

export const authService = {
  validateReCaptchaToken,
};
