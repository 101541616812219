import { useEffect, useState } from "react";
import { CONNECTION_STATE, KARAOKE_EVENTS } from "../../constants";
import Song from "./Song";

function Reserved({ connection, roomId, queue }) {
  const [list, setList] = useState([]);

  const removeSong = (song) => {
    if (!connection || connection.state !== CONNECTION_STATE.CONNECTED) {
      return null;
    }

    connection.invoke(KARAOKE_EVENTS.REMOVE_SONG, roomId, song._id);
  };

  useEffect(() => {
    const q = [...queue];
    q.shift();
    setList(q);
  }, [queue]);

  return (
    <>
      <div className="h4 text-center text-primary bg-light rounded">
        Reserved
      </div>
      <div className="list-container" style={{ paddingBottom: "98px" }}>
        <div className="list-group">
          {list.map((song) => {
            return (
              <Song
                key={song?._id}
                song={song}
                showSinger
                actions={
                  <button
                    type="button"
                    className="btn btn-danger btn-lg"
                    onClick={() => {
                      removeSong(song);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                }
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Reserved;
