import { useEffect } from "react";
import { karaokeService } from "../../services/karaokeService";
import Start from "./Start";
import { useNavigate } from "react-router-dom";
export default function Home() {
  const navigate = useNavigate();
  const roomId = karaokeService.getRoomId();

  const next = () => {
    navigate("/karaoke");
  };

  useEffect(() => {
    if (karaokeService.validateRoomId(roomId)) {
      navigate("/karaoke");
      return;
    }
  }, [navigate, roomId]);

  return <Start next={next} />;
}
