import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import { CommonLayout } from "./layouts/CommonLayout";
import { HomeLayout } from "./layouts/HomeLayout";
import { ProtectedLayout } from "./layouts/ProtectedLayout";
import HomePage from "./pages/HomePage";
import KaraokePage from "./pages/KaraokePage";
import GuestPage from "./pages/GuestPage";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<CommonLayout />}>
      <Route element={<HomeLayout />}>
        <Route path="/" element={<HomePage />} />
      </Route>
      <Route element={<ProtectedLayout />}>
        <Route path="/karaoke" element={<KaraokePage />} exact={true} />
        <Route path="/:roomId" element={<GuestPage />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Route>
  )
);
