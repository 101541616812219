import { useContext, useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import {
  CONNECTION_STATE,
  KARAOKE_EVENTS,
  SELECT_A_SONG_ID,
} from "../../constants";
import selectASong from "../../brand/SelectASong.jpg";
import { GuestContext } from "./GuestContext";
import { songsService } from "../../services/songsService";

function Controller({ connection, roomId, currentSong }) {
  const [song, setSong] = useState();
  const [duration, setDuration] = useState(100);
  const [currentTime, setCurrentTime] = useState(0);
  const [seeking, setSeeking] = useState();
  const { favorites, setFavorites } = useContext(GuestContext);

  const displayCurrentSong = (song) => {
    setSong(song);
  };

  const playPause = async () => {
    if (!connection || connection.state !== CONNECTION_STATE.CONNECTED) {
      return null;
    }

    await connection.invoke(KARAOKE_EVENTS.PLAY_PAUSE, roomId);
  };

  const reloadSong = async () => {
    if (!connection || connection.state !== CONNECTION_STATE.CONNECTED) {
      return null;
    }

    await connection.invoke(KARAOKE_EVENTS.RELOAD_SONG, roomId);
  };

  const nextSong = async () => {
    if (!connection || connection.state !== CONNECTION_STATE.CONNECTED) {
      return null;
    }

    await connection.invoke(KARAOKE_EVENTS.NEXT_SONG, roomId);
  };

  if (connection) {
    connection.on(KARAOKE_EVENTS.SONG_ENDED, (nextSong) => {
      displayCurrentSong(nextSong);
    });

    connection.on(KARAOKE_EVENTS.UPDATE_PROGRESS, (ct, d) => {
      setDuration(d);

      if (seeking) {
        return;
      }

      if (!song || song?.id === SELECT_A_SONG_ID) {
        setCurrentTime(0);
      } else {
        setCurrentTime(ct);
      }
    });
  }

  const addRemoveToFavorites = (song) => {
    const newFavorites = songsService.addRemoveToFavorites(song);

    setFavorites(newFavorites);
  };

  const setProgress = async (seconds) => {
    setSeeking(false);
    if (!connection || connection.state !== CONNECTION_STATE.CONNECTED) {
      return null;
    }

    await connection.invoke(KARAOKE_EVENTS.SET_PROGRESS, roomId, seconds);
  };

  useEffect(() => {
    setSong(currentSong);
  }, [currentSong]);

  return (
    <div className="container p-0">
      {song ? (
        <div className="container position-absolute">
          <button
            type="button"
            className="btn btn-light btn-lg m-1 p-0 text-danger btn-favorite pull-right"
            onClick={() => {
              addRemoveToFavorites(song);
            }}
          >
            <i
              className={
                favorites.find((x) => x.id === song?.id)
                  ? "fa fa-heart"
                  : "fa fa-heart-o"
              }
              style={{
                fontSize: "30px",
              }}
            ></i>
          </button>
        </div>
      ) : (
        <></>
      )}
      <Image
        src={song ? song.thumbnail : selectASong}
        className="img-fluid rounded video-playing"
      />
      <input
        type="range"
        className="w-100"
        style={{ visibility: song ? "visible" : "hidden" }}
        min={0}
        max={duration}
        value={currentTime}
        onChange={(e) => {
          setCurrentTime(e.target.value);
        }}
        onMouseDown={() => {
          setSeeking(true);
        }}
        onMouseUp={(e) => {
          setProgress(e.target.value);
        }}
        onTouchStart={() => {
          setSeeking(true);
        }}
        onTouchEnd={(e) => {
          setProgress(e.target.value);
        }}
      ></input>
      <div className="text-center mt-1">
        <Button
          variant="info"
          size="lg"
          onClick={playPause}
          style={{
            fontSize: "21px",
            padding: "11px",
          }}
        >
          <i className="fa fa-play"></i>
          <i className="fa fa-pause"></i>
        </Button>
        <Button
          variant="info"
          size="lg"
          className="m-1"
          onClick={reloadSong}
          style={{
            fontSize: "25px",
          }}
        >
          <i className="fa fa-rotate-left"></i>
        </Button>
        <Button
          variant="info"
          size="lg"
          onClick={nextSong}
          style={{
            fontSize: "25px",
          }}
        >
          <i className="fa fa-forward"></i>
        </Button>
      </div>
    </div>
  );
}

export default Controller;
