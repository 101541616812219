import QRCode from "react-qr-code";

export default function QrCode({ roomId }) {
  const qrCode = `${window.location.origin}/${roomId}`;

  return (
    <QRCode
      value={qrCode}
      style={{
        height: "auto",
        maxWidth: "100%",
        width: "100%",
      }}
      viewBox={`0 0 256 256`}
      className="img-thumbnail"
    ></QRCode>
  );
}
