import { Suspense } from "react";
import { useOutlet, Await } from "react-router-dom";
import { AppContext } from "../components/_shared/AppContext";

export const CommonLayout = () => {
  const outlet = useOutlet();

  const appContext = {};

  return (
    <Suspense>
      <Await
        errorElement={<div>Something went wrong</div>}
        children={() => (
          <AppContext.Provider value={{ appContext }}>
            {outlet}
          </AppContext.Provider>
        )}
      />
    </Suspense>
  );
};
