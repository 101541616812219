import { Image } from "react-bootstrap";

export default function Song({ song, onSelected, actions, showSinger }) {
  const handleClick = () => {
    if (onSelected) {
      onSelected(song);
    }
  };

  return (
    <div className="list-group-item p-1 mb-1">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center flex-grow-1">
          <Image
            className="img-fluid rounded video-thumbnail"
            src={song?.thumbnail}
            onClick={handleClick}
          />
          <div className="d-flex flex-column video-title">
            <span
              dangerouslySetInnerHTML={{ __html: song?.title }}
              onClick={handleClick}
            ></span>
            {showSinger ? (
              <small className="text-secondary">{song?.singer}</small>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="d-flex m-1">{actions}</div>
      </div>
    </div>
  );
}
