import { v4 as uuidv4, validate as uuidValidate } from "uuid";
import { STORAGE_KEYS } from "../constants";

const createRoom = () => {
  const roomId = uuidv4();

  sessionStorage.setItem(STORAGE_KEYS.ROOM_ID, roomId);
  sessionStorage.setItem(STORAGE_KEYS.SONGS, JSON.stringify([]));

  return roomId;
};

const joinRoom = (name, token) => {
  sessionStorage.setItem(STORAGE_KEYS.NAME, name);
  sessionStorage.setItem(STORAGE_KEYS.TOKEN, token);
};

const getToken = () => {
  return sessionStorage.getItem(STORAGE_KEYS.TOKEN);
};

const removeToken = () => {
  return sessionStorage.removeItem(STORAGE_KEYS.TOKEN);
};

const getName = () => {
  return sessionStorage.getItem(STORAGE_KEYS.NAME);
};

const removeName = () => {
  return sessionStorage.removeItem(STORAGE_KEYS.NAME);
};

const validateRoomId = (roomId) => {
  return uuidValidate(roomId);
};

const getRoomId = () => {
  return sessionStorage.getItem(STORAGE_KEYS.ROOM_ID);
};

export const karaokeService = {
  createRoom,
  joinRoom,
  getToken,
  removeToken,
  getName,
  removeName,
  validateRoomId,
  getRoomId,
};
