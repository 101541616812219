import { useContext, useState } from "react";
import { GuestContext } from "./GuestContext";
import { songsService } from "../../services/songsService";
import Song from "./Song";
import PreviewSongDialog from "./PreviewSongDialog";

function Favorites({ connection, roomId, name }) {
  const { favorites, setFavorites } = useContext(GuestContext);
  const [songPreview, setSongPreview] = useState();
  const [showPreviewDialog, setShowPreviewDialog] = useState();

  const showPreview = (s) => {
    setSongPreview(s);
    setShowPreviewDialog(true);
  };

  const handleClosePreview = () => {
    setShowPreviewDialog(false);
  };

  const remove = (song) => {
    const newFavorites = songsService.addRemoveToFavorites(song);

    setFavorites(newFavorites);
  };

  return (
    <>
      <div className="h4 text-center text-primary bg-light rounded">
        Favorites
      </div>
      <div className="list-container" style={{ paddingBottom: "98px" }}>
        <div className="list-group">
          {favorites.map((song) => {
            return (
              <Song
                key={song.id}
                song={song}
                onSelected={showPreview}
                actions={
                  <button
                    type="button"
                    className="btn btn-danger btn-lg"
                    onClick={() => {
                      remove(song);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </button>
                }
              />
            );
          })}
        </div>
      </div>
      <PreviewSongDialog
        connection={connection}
        roomId={roomId}
        name={name}
        show={showPreviewDialog}
        song={songPreview}
        handleClose={handleClosePreview}
      />
    </>
  );
}

export default Favorites;
