import { useEffect, useState } from "react";
import { Card, Image } from "react-bootstrap";
import { KARAOKE_EVENTS } from "../../constants";
import shrug from "../../brand/shrug.png";
import { songsService } from "../../services/songsService";

export default function NextSong({ connection }) {
  const [song, setSong] = useState();

  const displayNextSong = () => {
    const songs = songsService.getSongs();

    if (songs?.length <= 1) {
      setSong(null);
      return;
    }

    setSong(songs[1]);
  };

  useEffect(() => {
    if (connection) {
      connection.on(KARAOKE_EVENTS.SONG_ENDED, () => {
        displayNextSong();
      });

      connection.on(KARAOKE_EVENTS.QUEUE_UPDATED, () => {
        displayNextSong();
      });
    }
  }, [connection]);

  useEffect(() => {
    displayNextSong();
  }, []);

  return (
    <Card>
      <Card.Title className="text-center mb-0">NEXT SONG</Card.Title>
      <Card.Body className="p-0">
        {song ? (
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex flex-column">
              <img
                alt=""
                className="img-fluid rounded m-1"
                src={song.thumbnail}
              />
              <div className="d-flex flex-column m-1">
                <p className="h6">{song.title}</p>
                <div>
                  <small>SINGER:</small>{" "}
                  <span className="h6">{song.singer}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Image src={shrug} className="img-fluid" />
        )}
      </Card.Body>
    </Card>
  );
}
