import axios from "axios";
import { STORAGE_KEYS } from "../constants";

const jwtInterceptor = axios.create({});

jwtInterceptor.interceptors.request.use((config) => {
  const token = sessionStorage.getItem(STORAGE_KEYS.TOKEN);
  if (!token) {
    return;
  }

  config.baseURL = process.env.REACT_APP_API_BASEURL;

  config.headers["Authorization"] = `Bearer ${token}`;

  return config;
});

export default jwtInterceptor;
